import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { JSONTree } from 'react-json-tree';
import useCopyTextToClipboard from 'utils/hooks/useCopyTextToClipboard';
import { formatJson } from 'utils/json/utils';
type Props = {
  melding: string;
};

const theme = {
  scheme: 'bright',
  author: 'built-in',
  base00: '#ffffff',
  base01: '#00000008',
  base02: '#00ffff',
  base03: '#ff00ff',
  base04: '#00000038',
  base05: '#ff0000',
  base06: '#0000ff',
  base07: '#00ff00',
  base08: '#000000',
  base09: '#000000',
  base0A: '#000000',
};

const JsonResult = ({ melding }: Props) => {
  const [copyJson] = useCopyTextToClipboard();

  return (
    <Box>
      <Button
        sx={{ mb: 1 }}
        variant="contained"
        onClick={() => copyJson(formatJson(melding))}>
        Kopier JSON
      </Button>
      <br />
      <JSONTree
        data={JSON.parse(melding)}
        theme={theme}
        collectionLimit={100}
      />
    </Box>
  );
};
export default JsonResult;
