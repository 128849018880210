import { Button} from '@mui/material';
import { Box } from '@mui/system';
import XMLViewer from 'react-xml-viewer';
import useCopyTextToClipboard from 'utils/hooks/useCopyTextToClipboard';
import { formatXML } from 'utils/xml/utils';
type Props = {
  melding: string;
};

const XmlResult = ({ melding }: Props) => {
  const [copyXml] = useCopyTextToClipboard();
  return (
    <Box>
      <Button
        sx={{ mb: 1 }}
        variant="contained"
        onClick={() => copyXml(formatXML(melding))}>
        Kopier XML
      </Button>
      <XMLViewer xml={melding} />
    </Box>
  );
};
export default XmlResult;
