export const isValidJson = (jsonString: string): boolean => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
};

export const formatJson = (json: string): string => {
  return JSON.stringify(JSON.parse(json), null, 4);
};
