import {
  Alert,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { LoadingInputComponent } from 'components';
import { useSysProperties } from 'features/sys';
import React, { useState } from 'react';
import { NotatType, NotatProps } from './interface';
import {
  createPostData,
  initialFormState,
  initialFormStateInvalid,
} from './utils';
import { PickerFormat } from 'utils/date';
import { DateTime } from 'luxon';
import { DatePicker, DateValidationError } from '@mui/x-date-pickers';
import {
  DialogContentWrapper,
  DialogFormButtons,
} from 'features/deltaker/details/components/Henvendelser/HenvendelseDialog/components';
import NotatService from 'services/notat/notatService';
import { useSendData } from 'utils/hooks';
import { addNotification } from 'features/notification/notificationSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DetaljerSelector } from 'features/deltaker/details/interface';
import { detaljerSelector } from 'features/deltaker/details/selectors';
import { setShouldRefetchLogg } from 'features/deltaker/state/logg/slice';

const NyttNotatDialog: React.FC<NotatProps> = React.memo(({ close }) => {
  const { deltakerId }: DetaljerSelector = useAppSelector(detaljerSelector);
  const [formState, setFormState] = useState(initialFormState);
  const [formStateInvalid, setFormStateInvalid] = useState(
    initialFormStateInvalid
  );
  const dispatch = useAppDispatch();
  const { clearStatus, send, status } = useSendData();

  const { data: notatTypeData, isLoaded } =
    useSysProperties<NotatType>('deltakerNotatTyper');

  const handleChange = (
    event:
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | SelectChangeEvent<string | number | number[]>
  ) => {
    const { name, value } = event.target;
    if (name) {
      setFormState({ ...formState, [name]: value });
      setFormStateInvalid({ ...formStateInvalid, [name]: false });
    }
  };

  const handleInvalid = (event: React.FormEvent) => {
    event.preventDefault();
    const { name } = event.target as HTMLInputElement;
    setFormStateInvalid({ ...formStateInvalid, [name]: true });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!formState.notatTypeId) {
      setFormStateInvalid({ ...formStateInvalid, notatTypeId: true });
      return;
    }
    if (!formState.tidspunkt) {
      setFormStateInvalid({ ...formStateInvalid, tidspunkt: true });
      return;
    }

    if (!deltakerId) {
      return;
    }

    const deltakerNotatDto = createPostData(deltakerId, formState);
    send(async () => NotatService.postDeltakerNotat(deltakerNotatDto))
      .then((res) => {
        if (res === null) {
          dispatch(
            addNotification({
              message: { text: 'Notat opprettet' },
              variant: 'success',
            })
          );
          clearStatus();
          dispatch(setShouldRefetchLogg(true));
          close();
        }
      })
      .catch();
  };

  const handleTidspunktChange = (value: DateTime | null) => {
    setFormState({ ...formState, tidspunkt: value });
    setFormStateInvalid({ ...formStateInvalid, tidspunkt: false });
  };

  const handleTidspunktError = (error: DateValidationError) => {
    setFormStateInvalid({ ...formStateInvalid, tidspunkt: true });
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <DialogContentWrapper tekstOverskrift="Nytt Notat">
        <DatePicker
          label="Tidspunkt"
          value={formState.tidspunkt}
          format={PickerFormat.DATE.inputFormat}
          disableFuture
          onChange={handleTidspunktChange}
          onError={handleTidspunktError}
          slotProps={{
            textField: {
              id: 'tidspunkt',
              name: 'tidspunkt',
              required: true,
              error: formStateInvalid.tidspunkt,
              helperText: formStateInvalid.tidspunkt ? 'Ugyldig dato' : '',
              onInvalid: handleInvalid,
              fullWidth: true,
              sx: { mb: 1 },
            },
          }}
        />
        <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
          <LoadingInputComponent showLoading={!isLoaded}>
            <InputLabel id="notatType-select">Notat type</InputLabel>
            <Select
              labelId="notatType-select"
              name="notatTypeId"
              value={formState.notatTypeId ?? ''}
              onChange={handleChange}>
              <MenuItem value="">
                <em>Ingen valgt</em>
              </MenuItem>
              {notatTypeData
                ?.filter(({ manuellRegistrering }) => manuellRegistrering)
                .map((notatType) => (
                  <MenuItem key={notatType.id} value={notatType.id}>
                    {notatType.beskrivelse}
                  </MenuItem>
                ))}
            </Select>
          </LoadingInputComponent>
          {formStateInvalid.notatTypeId && (
            <FormHelperText sx={{ color: 'scr.red700' }}>
              Vennligst valg notattype
            </FormHelperText>
          )}
        </FormControl>
        <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
          <TextField
            id="loggtekst"
            label="loggtekst..."
            name="loggtekst"
            required
            fullWidth
            spellCheck={false}
            value={formState.loggtekst ?? ''}
            InputLabelProps={{ shrink: true }}
            error={formStateInvalid.loggtekst}
            helperText={
              formStateInvalid.loggtekst ? 'Vennligst fyll ut loggtekst' : ''
            }
            inputProps={{ maxLength: 100 }}
            onChange={handleChange}
            onInvalid={handleInvalid}
          />
        </FormControl>
        <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
          <TextField
            id="tekst"
            label="tekst..."
            name="tekst"
            fullWidth
            multiline
            spellCheck={false}
            rows={10}
            value={formState.tekst ?? ''}
            InputLabelProps={{ shrink: true }}
            error={formStateInvalid.tekst}
            helperText={
              formStateInvalid.tekst ? 'Vennligst fyll ut loggtekst' : ''
            }
            inputProps={{ maxLength: 3500 }}
            onChange={handleChange}
            onInvalid={handleInvalid}
          />
        </FormControl>
        <Alert severity="warning">
          Det er ikke mulig å redigere innholdet i notatet etter lagring.
        </Alert>
      </DialogContentWrapper>

      <DialogFormButtons
        disabled={status.loading}
        isLoading={!isLoaded}
        close={close}
        tekstHandling="Lagre"
      />
    </form>
  );
});

export default NyttNotatDialog;
