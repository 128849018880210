export const isValidXML = (xmlString: string) => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
    const parserError = xmlDoc.querySelector('parsererror');
    return !parserError;
  } catch (e) {
    return false;
  }
};

export const formatXML = (xml: string) => {
  try {
    const PADDING = '  ';
    const reg = /(>)(<)(\/*)/g;
    let formatted = '';
    let pad = 0;

    xml = xml.replace(reg, '$1\n$2$3');

    xml.split('\n').forEach((node) => {
      let indent = '';
      if (node.match(/^<\/\w/)) pad--;
      for (let i = 0; i < pad; i++) indent += PADDING;
      formatted += indent + node + '\n';
      if (node.match(/^<\w[^>]*[^/]>.*$/)) pad++;
    });

    return formatted.trim();
  } catch (e) {
    return xml;
  }
};